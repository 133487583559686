import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import CustomButton from "../../CustomBtn";
import CopyToClipboard from "react-copy-to-clipboard";

const ToastContainer = styled.div`
  position: fixed;
  bottom: 10%;
  background-color: #4caf50;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  z-index: 1;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const ContainerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 0px !important;
  }
`;

const InnerContainerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWrapper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;

const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;

const Tokenomics = () => {
  const [copied, setCopied] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "0x8eD6E70ddfBE438E471B7177C1B47d6b1C4c6C0d";

  const handleCopy = () => {
    setCopied(true);
    setShowToast(true);
    setTimeout(() => {
      setCopied(false);
      setShowToast(false);
    }, 2000);
  };

  return (
    <>
      <ContainerWraper maxWidth="xxl" id="tokenomics">
        <InnerContainerWraper>
          <GridWrapper container data-aos="fade-up">
            <GridItem container item xs={12}>
              <CustomGrid item xs={12}>
                <SectionHeading fs="100px" fs1175="76px" fs1024="62px" fs599="62px" data-aos="zoom-in">
                  Tokenomics
                </SectionHeading>
              </CustomGrid>
            </GridItem>
            <CustomGrid container item xs={12} data-aos="fade-up">
              <CustomGrid fd="column" item xs={12} sm={3}>
                <SectionHeading fs="60px" fs1175="46px" fs1024="32px" fs599="32px" data-aos="zoom-in">
                  Bitcoin
                </SectionHeading>
                <SectionHeading fs="50px" fs1175="36px" fs1024="22px" fs599="22px" data-aos="zoom-in">
                  Symbol
                </SectionHeading>
              </CustomGrid>
              <CustomGrid fd="column" item xs={12} sm={3}>
                <SectionHeading fs="60px" fs1175="46px" fs1024="32px" fs599="32px" data-aos="zoom-in">
                  21,000,000
                </SectionHeading>
                <SectionHeading fs="50px" fs1175="36px" fs1024="22px" fs599="22px" data-aos="zoom-in">
                  Supply
                </SectionHeading>
              </CustomGrid>
              <CustomGrid fd="column" item xs={12} sm={3}>
                <SectionHeading fs="60px" fs1175="46px" fs1024="32px" fs599="32px" data-aos="zoom-in">
                  0/0
                </SectionHeading>
                <SectionHeading fs="50px" fs1175="36px" fs1024="22px" fs599="22px" data-aos="zoom-in">
                  Taxes
                </SectionHeading>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <SectionHeading fs="50px" fs1175="36px" fs1024="22px" fs599="22px" data-aos="zoom-in">
                {addressToCopy}
              </SectionHeading>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <CopyToClipboard text={addressToCopy} onCopy={handleCopy}>
                <CustomButton>{copied ? "Copied!" : "Copy Address"}</CustomButton>
              </CopyToClipboard>
            </CustomGrid>
            <ToastContainer show={showToast}>{addressToCopy}</ToastContainer>
          </GridWrapper>
        </InnerContainerWraper>
      </ContainerWraper>
    </>
  );
};

export default Tokenomics;
