import React, { useEffect } from "react";
import HeroSection from "../../Components/Sections/S1-Hero";
import { PageWrapper } from "../../Styles/style";
import FeaturesSection from "../../Components/Sections/S2-Features";
import AOS from "aos";
import "aos/dist/aos.css";
import Tokenomics from "../../Components/Sections/S3-Tokenomics";
import Footer from "../../Components/Sections/S4-Footer";

const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <FeaturesSection/>
      <Tokenomics />
      <Footer/>
    </PageWrapper>
  );
};

export default MainPage;
