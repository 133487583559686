import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import {  SectionHeading } from "../../../Styles/style";
const ContinerWraper = styled(Container)`
  min-height: 130vh;
  width: 100%;
  padding: 50px 0px !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  padding: 0px !important;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 50px 0px;
  display: flex;
  align-items: start;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;

const FeaturesSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <GridWraper container>
            <SectionHeading
              fs="60px"
              fs1175="76px"
              fs1024="62px"
              fs599="32px"
              sx={{wordSpacing:"10px",lineHeight:"1.3 !important"}}
            >
             Welcome to ByteBuck, your ticket to the forefront of the crypto
              revolution. If you missed out on the Bitcoin frenzy, ByteBuck is 
              here to redefine the landscape as the digital juggernaut of choice.
              According to Grok's latest reports, ByteBuck would have been Bitcoin's 
              undeniable alter ego if the pioneer had never existed. ByteBuck isn't just
               a token; it's a strategic move for explosive growth, boasting a secure and 
               robust network with bullish momentum on the rise. Join us on this journey,
              as ByteBuck becomes the underdog poised to steal the spotlight and lead you to 
              new heights in the crypto universe. Explore ByteBuck on our website and join the community 
             on Telegram and Twitter. Fasten your seatbelts; ByteBuck is gearing up for a
            meteoric rise, and you're invited to be part of the wave!
            </SectionHeading>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default FeaturesSection;
