import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import heroImage from "../../../../assets/images/heroImage.png";
import etherscan from "../../../../assets/images/etherscan.png";
import dex from "../../../../assets/images/dex.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import backgroundImage from "../../../../assets/images/backgroundImage.png";
import LockerMenu from "../../LockerMenu";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background: radial-gradient(closest-sidetransparent, #000);
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
const BackgroundDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* z-index: -1; */
`;

const HeroSection = () => {
  return (
    <>
      <BackgroundDiv />
      <ContinerWraper maxWidth="xxl" id="home">
        <InnerContinerWraper>
          <LockerMenu />
          <CustomGrid container p="30px 0">
            <CustomGrid item xs={12} sm={9} md={6} fd="column">
              <DataImage data-aos="zoom-in" src={heroImage} mw="100%" />
              <SectionHeading
                data-aos="zoom-in"
                fs="100px"
                fs1175="76px"
                fs1024="62px"
                fs599="62px"
              >
                ByteBuckt
              </SectionHeading>
              <Link
                sx={{ cursor: "pointer" }}
                href="https://app.uniswap.org/swap?outputCurrency=0x8eD6E70ddfBE438E471B7177C1B47d6b1C4c6C0d&chain=ethereum"
                target="blank"
              >
                <CustomButton m="10px 0 15px">Buy Now</CustomButton>
              </Link>
              <div
                data-aos="fade-up"
                style={{ display: "flex", gap: "20px", padding: "10px 0px" }}
              >
                <Link
                  sx={{ cursor: "pointer" }}
                  href="https://etherscan.io/token/0x8ed6e70ddfbe438e471b7177c1b47d6b1c4c6c0d"
                  target="blank"
                >
                  <DataImage src={etherscan} />
                </Link>
                <Link
                  sx={{ cursor: "pointer" }}
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x8eD6E70ddfBE438E471B7177C1B47d6b1C4c6C0d"
                  target="blank"
                >
                  <DataImage src={dex} />
                </Link>
                <Link
                  sx={{ cursor: "pointer" }}
                  href="https://twitter.com/bytebuck"
                  target="blank"
                >
                  <DataImage src={x} />
                </Link>
                <Link
                  sx={{ cursor: "pointer" }}
                  href="https://t.me/bytebuck"
                  target="blank"
                >
                  <DataImage src={tg} />
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
