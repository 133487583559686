import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import { Container } from "@mui/material";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "Home",
      link: "#home",
    },
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
  ];
  return (
    <Container  data-aos="fade-down" sx={{ padding: "0px !important" }}>
      <DesktopMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
